import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import Logo from "./Logo"
import NavbarLinks from "./NavbarLinks"

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Navigation = styled.nav`
  height: 4rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-bottom: 2px solid #333333;
  margin: 1rem 1rem 0rem 1rem;
  ${mq[1]} {
    margin: 1rem 1rem 0rem 1rem;
  }
  ${mq[2]} {
    margin: 1.5rem 1.5rem 0rem 1.5rem;
  }
  ${mq[3]} {
    margin: 2rem 2rem 0rem 2rem;
  }

  z-index: 2;
  align-items: center;

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`
const Navbox = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 2rem;
    background-color: #fff;
    transition: all 0.3s ease-in;
    top: 5rem;
    left: ${props => (props.open ? "100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <Navigation>
      <Link to="/">
        <Logo />
      </Link>

      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks />
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar
