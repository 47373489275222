import React from "react"
import styled from "@emotion/styled"

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Wrapper = styled.section`
  margin: 1em 1em 0 1em;
  ${mq[2]} {
    margin: 1.5em 1.5em 0 1.5em;
  }
  ${mq[3]} {
    margin: 2em 2em 0 2em;
  }

  p {
    font-family: Arial, Helvetica, sans-serif;
    border-top: 1px solid #111;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

const Footer = () => (
  <Wrapper>
    <p>© {new Date().getFullYear()}, Well-Told, LLC.</p>
  </Wrapper>
)

export default Footer
