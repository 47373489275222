/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import emotionReset from "emotion-reset"

import Navbar from "./Navbar/Navbar"
import Footer from "./Footer/footer"

const breakpoints = [576, 768, 992, 1200]
const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={css`
          ${emotionReset}
          /* Extra Resets */
          *,
          *::after,
          *::before {
            box-sizing: border-box;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
          }

          blockquote {
            color: red;
            border-left: 1px solid black;
            margin: 2rem;
            padding-left: 1rem;
          }

          /* Body Grid */
          :root {
            --offset: 0.5rem;
            --columns: 2;
            ${mq[0]} {
              --columns: 4;
            }
            ${mq[1]} {
              --columns: 4;
            }
            ${mq[2]} {
              --offset: 0.75rem;
              --columns: 6;
            }
            ${mq[3]} {
              --offset: 1rem;
              --columns: 6;
            }
            --gutter: 1px;
            --color: #ffffff;
          }

          /* Body Grid Helper variables */
          :root {
            --column-width: calc((100% / var(--columns)) - var(--gutter));
            --background-width: calc(100% + var(--gutter));
            --background-col: repeating-linear-gradient(
              to left,
              var(--color),
              var(--color) 2px,
              transparent 2px,
              transparent calc(100% / var(--columns))
            );
          }

          /* Body Grid Styling */
          html {
            position: relative;
          }

          html::before {
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-right: auto;
            margin-left: auto;
            width: calc(100% - (2 * var(--offset)));
            min-height: 100vh;
            content: "";
            background-image: var(--background-col);
            background-size: var(--background-width) 100%;
            z-index: -1000;
            pointer-events: none;
          }

          body {
            background-color: #f2f2f2;
          }

          * {
            font-family: fann-grotesque-regular, Arial, Helvetica, sans-serif;
            font-weight: normal;
            line-height: 1.4;
          }

          strong {
            font-weight: bold;
          }

          em {
            font-style: italic;
          }

          h3 {
            font-weight: bold;
          }

          /* Typography */
          p,
          li,
          h3 {
            font-size: 1rem;
            ${mq[0]} {
              font-size: 1rem;
            }
            ${mq[1]} {
              font-size: 1.125rem;
            }
            ${mq[2]} {
              font-size: 1.25rem;
            }
            ${mq[3]} {
              font-size: 1.25rem;
            }
          }

          h1 {
            font-size: 1.625rem;
            ${mq[0]} {
              font-size: 1.625rem;
            }
            ${mq[1]} {
              font-size: 1.875rem;
            }
            ${mq[2]} {
              font-size: 2rem;
            }
            ${mq[3]} {
              font-size: 2.125rem;
            }
          }

          h2 {
            font-size: 1.625rem;
            ${mq[0]} {
              font-size: 1.75rem;
            }
            ${mq[1]} {
              font-size: 1.875rem;
            }
            ${mq[2]} {
              font-size: 2rem;
            }
            ${mq[3]} {
              font-size: 2.125rem;
            }
          }
        `}
      />
      <Navbar siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
