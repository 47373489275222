import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const breakpoints = [576, 768, 992, 1200]

const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 2vw;
  transition: all 200ms ease-in;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  ${mq[0]} {
    font-size: 1rem;
  }
  ${mq[1]} {
    font-size: 1.125rem;
  }
  ${mq[2]} {
    font-size: 1.25rem;
  }
  ${mq[3]} {
    font-size: 1.25rem;
  }

  :after {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 2px;
    transition: all 200ms ease-in;
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }
`

const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/projects/">Work</NavItem>
      <NavItem to="/about/">About</NavItem>
      <NavItem to="/contact/">Contact</NavItem>
    </>
  )
}

export default NavbarLinks
